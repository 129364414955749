import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import type {
	LoadAuthUserResponse,
	LoginPayload,
	LoginResponse,
	CreateInitialAdminUserInstallPayload,
	UsersUnreadNotificationsResponse,
	LoadAuthUserPayload,
	PasswordResetCheckHashUsersPayload,
	PasswordResetCheckHashResponse,
	PasswordResetPayload,
	PasswordUpdatePayload,
	PasswordUpdateResponse,
	PasswordResetResponse,
	SsoAuthorizePayload,
	SsoAuthorizeResponse,
	TfaLoginPayload,
	TfaLoginResponse,
	Disable2faPayload,
	Disable2faResponse,
	Enable2faResponse,
	Get2faResponse,
	GetActiveOrganizationTeamRequest,
	GetActiveOrganizationTeamResponse,
	LogoutResponse,
	GetSanityCheckResponse,
	RegisterPartnerPayload,
	RegisterPartnerResponse,
	PartialUpdateUserResponse,
	PartialUpdateUserPayload,
} from './auth.interface';
import {
	Body,
	GET,
	HttpBuilder,
	POST,
	BodyType,
	QueryType,
	QueryData,
	Path,
	PATCH,
	ParamType,
	DELETE,
	PUT,
	Header,
	IGNORE_ERROR_HEADER,
} from '@imt-web-zone/shared/util';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';

@Injectable({ providedIn: 'root' })
export class AuthService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@GET('users/me')
	public getMe$(@QueryData queryData: QueryType<LoadAuthUserPayload>): Observable<LoadAuthUserResponse> {
		return undefined as unknown as Observable<LoadAuthUserResponse>;
	}

	@GET('imt/hq/sanity-check')
	public sanityCheck(): Observable<GetSanityCheckResponse> {
		return undefined as unknown as Observable<GetSanityCheckResponse>;
	}

	@POST('login')
	public login$(@Body body: BodyType<LoginPayload>): Observable<LoginResponse> {
		return undefined as unknown as Observable<LoginResponse>;
	}

	@POST('logout')
	public logout$(@QueryData query?: { soft: boolean }): Observable<LogoutResponse> {
		return undefined as unknown as Observable<LogoutResponse>;
	}

	@GET('sso/authorize')
	public oauthAuthorize$(@QueryData data: SsoAuthorizePayload): Observable<SsoAuthorizeResponse> {
		return undefined as unknown as Observable<SsoAuthorizeResponse>;
	}

	@POST('login/2fa')
	public tfaAuth$(@Body data: BodyType<TfaLoginPayload>): Observable<TfaLoginResponse> {
		return undefined as unknown as Observable<TfaLoginResponse>;
	}

	@GET('users/me/2fa')
	public getTfaData$(): Observable<Get2faResponse> {
		return undefined as unknown as Observable<Get2faResponse>;
	}

	@DELETE('users/me/2fa')
	public disableUser2fa$(@Body data: Disable2faPayload): Observable<Disable2faResponse> {
		return undefined as unknown as Observable<Disable2faResponse>;
	}

	@POST('users/me/2fa')
	public enable2fa$(): Observable<Enable2faResponse> {
		return undefined as unknown as Observable<Enable2faResponse>;
	}

	@GET('users/unread-notifications')
	public usersUnreadNotifications$(): Observable<UsersUnreadNotificationsResponse> {
		return undefined as unknown as Observable<UsersUnreadNotificationsResponse>;
	}

	@POST('install/create-user')
	public createInitialAdminUserInstall$(
		@Body data: BodyType<CreateInitialAdminUserInstallPayload>,
	): Observable<void> {
		return undefined as unknown as Observable<void>;
	}

	@POST('install/skip')
	public skipInstallProcessInstall$(): Observable<void> {
		return undefined as unknown as Observable<void>;
	}

	@PATCH('users/{userId}')
	public patchUserLanguage$(
		@Path('userId') userId: ParamType<PartialUpdateUserPayload, 'userId'>,
		@Body payload: BodyType<PartialUpdateUserPayload>,
	): Observable<PartialUpdateUserResponse> {
		return undefined as unknown as Observable<PartialUpdateUserResponse>;
	}

	@POST('users/password-reset-demand')
	public demandPasswordReset$(@Body payload: { email: string }): Observable<{ ok: 1 }> {
		return undefined as unknown as Observable<{ ok: 1 }>;
	}

	@GET('users/password-reset')
	public passwordResetCheckHash$(
		@QueryData queryData: QueryType<PasswordResetCheckHashUsersPayload>,
	): Observable<PasswordResetCheckHashResponse> {
		return undefined as unknown as Observable<PasswordResetCheckHashResponse>;
	}

	@POST('users/password-reset')
	public passwordReset$(@Body data: BodyType<PasswordResetPayload>): Observable<PasswordResetResponse> {
		return undefined as unknown as Observable<PasswordResetResponse>;
	}

	@PUT('users/{userId}/attributes/password')
	public passwordUpdate$(
		@Path('userId') userId: ParamType<PasswordUpdatePayload, 'userId'>,
		@Body data: BodyType<PasswordUpdatePayload>,
	): Observable<PasswordUpdateResponse> {
		return undefined as unknown as Observable<PasswordUpdateResponse>;
	}

	@GET('imt/active-organization-team')
	public getActiveOrganizationTeam$(
		@QueryData query: QueryType<GetActiveOrganizationTeamRequest>,
	): Observable<GetActiveOrganizationTeamResponse> {
		return undefined as unknown as Observable<GetActiveOrganizationTeamResponse>;
	}

	@POST('affiliate/partner-register')
	public registerPartner(
		@Body body: BodyType<RegisterPartnerPayload>,
		@Header(IGNORE_ERROR_HEADER) contentType?: string,
	): Observable<RegisterPartnerResponse> {
		return undefined as unknown as Observable<RegisterPartnerResponse>;
	}
}
