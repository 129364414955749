import { Directive, Input, OnInit, OnChanges, SimpleChanges, HostBinding, inject } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router, RouterLink, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { TakeUntilDestroy, takeUntilDestroyed } from '@imt-web-zone/shared/util';

const DIRECTIVE_SELECTOR = 'imtUiRouterLinkDomain';

@TakeUntilDestroy()
@Directive({
	selector: `a[routerLink][${DIRECTIVE_SELECTOR}]`,
	exportAs: DIRECTIVE_SELECTOR,
})
export class UiRouterLinkDomainDirective implements OnInit, OnChanges {
	private routerLink = inject(RouterLink);
	private router = inject(Router);
	private locationStrategy = inject(LocationStrategy);

	@HostBinding('attr.href') public attrHref: string | null = null;
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input(DIRECTIVE_SELECTOR) public domain!: string;

	private routerLinkOnChanges!: RouterLink['ngOnChanges'];

	private get host(): string {
		return window.location.host;
	}

	private get routerLinkHref(): string | null {
		if (!this.routerLink.urlTree) {
			return null;
		}

		return this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.routerLink.urlTree));
	}

	public ngOnInit(): void {
		// tap into the original onChanges method of routerLink and bind original routerLink
		// (so it will be called at routerLink scope)
		this.routerLinkOnChanges = this.routerLink.ngOnChanges.bind(this.routerLink);
		// and call this.ngOnChanges when routerlink's onChanges is called
		this.routerLink.ngOnChanges = (changes) => {
			this.routerLinkOnChanges(changes);
			this.ngOnChanges(changes);
		};

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntilDestroyed(this),
			)
			.subscribe(() => this.resolveRouterLinkHref(this.host, this.domain, this.routerLinkHref));
	}

	public ngOnChanges(_: SimpleChanges | undefined): void {
		this.resolveRouterLinkHref(this.host, this.domain, this.routerLinkHref);
	}

	private resolveRouterLinkHref(host: string, domain: string | undefined, routerLinkHref: string | null): void {
		if (!domain || host === domain) {
			// set originally calculated routerLink href property
			this.attrHref = routerLinkHref;
			return;
		}

		// when domain is different, set entire URL containing different domain
		this.attrHref = `https://${domain}${routerLinkHref}`;
	}
}
