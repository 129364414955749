import { Injectable, inject } from '@angular/core';
import {
	Body,
	GET,
	POST,
	PATCH,
	DELETE,
	Path,
	HttpBuilder,
	QueryData,
	QueryType,
	Pagination,
	PaginationType,
	ParamType,
	BodyType,
} from '@imt-web-zone/shared/util';
import {
	ListUsersPayload,
	ListUsersResponse,
	ListAdminUsersResponse,
	ListAdminUsersPayload,
	DeleteUserPayload,
	DeleteUserResponse,
	PartialUpdateAdminUserPayload,
	LoginAsAdminUserPayload,
	LoginAsAdminUserResponse,
	DisableAdminUser2faResponse,
	DisableAdminUser2faPayload,
	EnableDisableUserFeaturePayload,
	EnableDisableUserFeatureResponse,
	EnableDisableUserOauthScopePayload,
	EnableDisableUserOauthScopeResponse,
	LoadHqAdminUserPayload,
	LoadHqAdminUserResponse,
	UnpairUserSsoPayload,
	UnpairUserSsoResponse,
	LoadAdminUserResponse,
	LoadAdminUserPayload,
} from './users.interface';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';
import { Effect, EffectInit } from '@imt-web-zone/core/util-state-effect';
import { RunEffectAction } from '@imt-web-zone/shared/util-store';
import { PartialUpdateAdminUserResponse } from '@imt-web-zone/zone/state-auth';

@Injectable({ providedIn: 'root' })
@EffectInit()
export class UsersService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@Effect<ListUsersResponse>({ responseEntityPath: 'users' })
	@GET('users')
	public listUsers$(
		@QueryData queryData: QueryType<ListUsersPayload>,
		@Pagination pg: PaginationType<ListUsersPayload>,
	): RunEffectAction<ListUsersPayload, ListUsersResponse> {
		return undefined as unknown as RunEffectAction<ListUsersPayload, ListUsersResponse>;
	}

	@Effect<ListAdminUsersResponse>({ responseEntityPath: 'users' })
	@GET('admin/users-detail')
	public listAdminUsers$(
		@QueryData queryData: QueryType<ListAdminUsersPayload>,
		@Pagination pg: PaginationType<ListAdminUsersPayload>,
	): RunEffectAction<ListAdminUsersPayload, ListAdminUsersResponse> {
		return undefined as unknown as RunEffectAction<ListAdminUsersPayload, ListAdminUsersResponse>;
	}

	@Effect<LoadAdminUserResponse>({ responseEntityPath: 'user' })
	@GET('admin/users/{userId}')
	public loadAdminUser$(
		@Path('userId') userId: ParamType<LoadAdminUserPayload, 'userId'>,
	): RunEffectAction<LoadAdminUserPayload, LoadAdminUserResponse> {
		return undefined as unknown as RunEffectAction<LoadAdminUserPayload, LoadAdminUserResponse>;
	}

	@Effect<ListAdminUsersResponse>({ responseEntityPath: 'users' })
	@GET('users-detail')
	public listHqAdminUsers$(
		@QueryData queryData: QueryType<ListAdminUsersPayload>,
		@Pagination pg: PaginationType<ListAdminUsersPayload>,
	): RunEffectAction<ListAdminUsersPayload, ListAdminUsersResponse> {
		return undefined as unknown as RunEffectAction<ListAdminUsersPayload, ListAdminUsersResponse>;
	}

	@Effect<LoadHqAdminUserResponse>({ responseEntityPath: 'user' })
	@GET('users/{userId}')
	public loadHqAdminUser$(
		@Path('userId') userId: ParamType<LoadHqAdminUserPayload, 'userId'>,
	): RunEffectAction<LoadHqAdminUserPayload, LoadHqAdminUserResponse> {
		return undefined as unknown as RunEffectAction<LoadHqAdminUserPayload, LoadHqAdminUserResponse>;
	}

	@Effect<EnableDisableUserFeatureResponse>({ responseEntityPath: 'enabled' })
	@POST('admin/users/{userId}/features/{featureName}')
	public enableFeature$(
		@Path('userId') userId: string,
		@Path('featureName') featureName: string,
	): RunEffectAction<EnableDisableUserFeaturePayload, EnableDisableUserFeatureResponse> {
		return undefined as unknown as RunEffectAction<
			EnableDisableUserFeaturePayload,
			EnableDisableUserFeatureResponse
		>;
	}

	@Effect<EnableDisableUserFeatureResponse>({ responseEntityPath: 'enabled' })
	@DELETE('admin/users/{userId}/features/{featureName}')
	public disableFeature$(
		@Path('userId') userId: string,
		@Path('featureName') featureName: string,
	): RunEffectAction<EnableDisableUserFeaturePayload, EnableDisableUserFeatureResponse> {
		return undefined as unknown as RunEffectAction<
			EnableDisableUserFeaturePayload,
			EnableDisableUserFeatureResponse
		>;
	}

	@Effect<DisableAdminUser2faResponse>({ responseEntityPath: 'tfaEnabled' })
	@DELETE('admin/users/{userId}/2fa')
	public disableAdminUser2fa$(
		@Path('userId') userId: DisableAdminUser2faPayload['params']['userId'],
	): RunEffectAction<DisableAdminUser2faPayload, DisableAdminUser2faResponse> {
		return undefined as unknown as RunEffectAction<DisableAdminUser2faPayload, DisableAdminUser2faResponse>;
	}

	@Effect<DeleteUserResponse>({ responseEntityPath: 'user' })
	@DELETE('admin/users/{userId}')
	public deleteUser$(
		@Path('userId') userId: DeleteUserPayload['params']['userId'],
	): RunEffectAction<DeleteUserPayload, DeleteUserResponse> {
		return undefined as unknown as RunEffectAction<DeleteUserPayload, DeleteUserResponse>;
	}

	@Effect<PartialUpdateAdminUserResponse>({ responseEntityPath: 'user' })
	@PATCH('admin/users/{userId}')
	public partialUpdateAdminUser$(
		@Path('userId')
		userId: ParamType<PartialUpdateAdminUserPayload, 'userId'>,
		@Body data: BodyType<PartialUpdateAdminUserPayload>,
	): RunEffectAction<PartialUpdateAdminUserPayload, PartialUpdateAdminUserResponse> {
		return undefined as unknown as RunEffectAction<PartialUpdateAdminUserPayload, PartialUpdateAdminUserResponse>;
	}

	@Effect<LoginAsAdminUserResponse>({ responseEntityPath: 'go' })
	@POST('admin/users/{userId}/login')
	public loginAsAdminUser$(
		@Path('userId') userId: LoginAsAdminUserPayload['params']['userId'],
	): RunEffectAction<LoginAsAdminUserPayload, LoginAsAdminUserResponse> {
		return undefined as unknown as RunEffectAction<LoginAsAdminUserPayload, LoginAsAdminUserResponse>;
	}

	@Effect<EnableDisableUserOauthScopeResponse>({ responseEntityPath: 'oauthScope' })
	@POST('users/{userId}/oauth-scopes/{scopeId}')
	public enableUserOauthScope$(
		@Path('userId') userId: ParamType<EnableDisableUserOauthScopePayload, 'userId'>,
		@Path('scopeId') scopeId: ParamType<EnableDisableUserOauthScopePayload, 'scopeId'>,
	): RunEffectAction<EnableDisableUserOauthScopePayload, EnableDisableUserOauthScopeResponse> {
		return undefined as unknown as RunEffectAction<
			EnableDisableUserOauthScopePayload,
			EnableDisableUserOauthScopeResponse
		>;
	}

	@Effect<EnableDisableUserOauthScopeResponse>({ responseEntityPath: 'oauthScope' })
	@DELETE('users/{userId}/oauth-scopes/{scopeId}')
	public disableUserOauthScope$(
		@Path('userId') userId: ParamType<EnableDisableUserOauthScopePayload, 'userId'>,
		@Path('scopeId') scopeId: ParamType<EnableDisableUserOauthScopePayload, 'scopeId'>,
	): RunEffectAction<EnableDisableUserOauthScopePayload, EnableDisableUserOauthScopeResponse> {
		return undefined as unknown as RunEffectAction<
			EnableDisableUserOauthScopePayload,
			EnableDisableUserOauthScopeResponse
		>;
	}

	@Effect<UnpairUserSsoResponse>({ responseEntityPath: 'user' })
	@DELETE('users/{userId}/social-sso/{ssoProvider}')
	public unpairUserSso$(
		@Path('userId') userId: ParamType<UnpairUserSsoPayload, 'userId'>,
		@Path('ssoProvider') ssoProvider: ParamType<UnpairUserSsoPayload, 'ssoProvider'>,
	): RunEffectAction<UnpairUserSsoPayload, UnpairUserSsoResponse> {
		return undefined as unknown as RunEffectAction<UnpairUserSsoPayload, UnpairUserSsoResponse>;
	}
}
