import { Injectable, Injector, inject } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TakeUntilDestroy, takeUntilDestroyed } from '@imt-web-zone/shared/util';
import { Subscription } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import {
	UiPanelMenuComponent,
	CONNECTED_TO_TOKEN,
	PANEL_MENU_REF_TOKEN,
	UiPanelMenuRef,
} from './ui-panel-menu.component';

@TakeUntilDestroy()
@Injectable({ providedIn: 'root' })
export class UiPanelMenuService {
	private overlay = inject(Overlay);

	private overlayRef: OverlayRef | null = null;
	private bdClickSubscription: Subscription | null = null;

	constructor() {
		(window as any).uiPanelMenuService = this;
	}

	public open(connectTo: Element) {
		if (this.overlayRef) {
			this.close();
			return;
		}

		this.overlayRef = this.getOverlayRef(connectTo);

		this.bdClickSubscription = this.overlayRef
			.backdropClick()
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => this.close());

		const portal = new ComponentPortal(
			UiPanelMenuComponent,
			null,
			Injector.create({
				providers: [
					{ provide: CONNECTED_TO_TOKEN, useValue: connectTo },
					{ provide: PANEL_MENU_REF_TOKEN, useValue: { close: this.close.bind(this) } as UiPanelMenuRef },
				],
			}),
		);
		this.overlayRef.attach(portal);
	}

	public close() {
		if (this.overlayRef) {
			this.overlayRef.detach();
			this.overlayRef = null;
		}

		if (this.bdClickSubscription) {
			this.bdClickSubscription.unsubscribe();
			this.bdClickSubscription = null;
		}
	}

	private getOverlayRef(connectTo: Element) {
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(connectTo)
			.withPush(true)
			.withViewportMargin(10)
			.withFlexibleDimensions(true)
			.withPositions([
				{ originX: 'center', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
				{ originX: 'center', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
			]);

		const scrollStrategy = this.overlay.scrollStrategies.block();

		return this.overlay.create({
			disposeOnNavigation: true,
			hasBackdrop: true,
			positionStrategy,
			scrollStrategy,
			backdropClass: 'cdk-overlay-transparent-backdrop',
		});
	}
}
