import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, inject } from '@angular/core';
import { ImtUiModalComponent } from '../imt-ui-modal.component';
import { IMT_UI_TRANSLATE_SERVICE_TOKEN, ImtUiLangService } from '@imt-web-zone/shared/util-imt-translate';
import { DismissReason } from '../imt-ui-modal-base.component';
import { TakeUntilDestroy, takeUntilDestroyed } from '@imt-web-zone/shared/util';

/**
 * Example of usage:
 * <example-url>/demo/modal</example-url>
 */

@TakeUntilDestroy()
@Component({
	selector: 'imt-ui-modal-footer',
	templateUrl: './imt-ui-modal-footer.component.html',
	styleUrls: ['./imt-ui-modal-footer.component.scss'],
})
export class ImtUiModalFooterComponent implements OnInit {
	private parent = inject(ImtUiModalComponent, { host: true, optional: true });
	private cdr = inject(ChangeDetectorRef);
	private langService = inject<ImtUiLangService>(IMT_UI_TRANSLATE_SERVICE_TOKEN);

	@Input() public closeOnSubmit = true;
	@Input() public hideBtnPrimary = false;
	@Input() public btnPrimaryText: string | null = null;
	@Input() public btnPrimaryClass = 'btn-primary';
	@Input() public disableBtnPrimary = false;
	@Input() public spacingBetweenButtons = false;
	/**
	 * Result returned on close event
	 */
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Input() public result: boolean | MouseEvent = true;

	@Input() public hideBtnSecondary = false;
	@Input() public btnSecondaryText: string | null = null;
	@Input() public disableBtnSecondary = false;
	/**
	 * Modal dissmiss reason
	 */
	@Input() public reason: DismissReason = DismissReason.BtnClick;

	@Output() public dismissed: EventEmitter<DismissReason> = new EventEmitter();
	@Output() public submited: EventEmitter<boolean | MouseEvent | unknown> = new EventEmitter();

	public ngOnInit() {
		this.langService
			.selectTranslate(['imt_ui_common.ok', 'imt_ui_common.close'])
			.pipe(takeUntilDestroyed(this))
			.subscribe(([ok, close]) => {
				this.btnPrimaryText = this.btnPrimaryText || ok;
				this.btnSecondaryText = this.btnSecondaryText || close;
				this.cdr.detectChanges();
			});
	}

	public onDismiss(reason?: DismissReason): void {
		if (this.parent) {
			this.parent.onDismiss(reason);
		}
		if (this.dismissed) {
			this.dismissed.emit(reason);
		}
	}

	public onSubmit(result?: boolean | MouseEvent | unknown): void {
		if (this.closeOnSubmit && this.parent) {
			this.parent.onClose(result);
		}
		if (this.submited) {
			this.submited.emit(result);
		}
	}
}
