import { Action, State, StateContext, Store } from '@ngxs/store';
import { effectFactory } from '@imt-web-zone/shared/util-store';
import { catchError, mergeMap, startWith, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { inject, Injectable, NgZone } from '@angular/core';
import { ImmutableContext } from '@imt-web-zone/shared/util-immer-adapter';
import { TranslocoService } from '@jsverse/transloco';
import { firstValueFrom, interval, of, Subscription } from 'rxjs';
import { environment } from '@imt-web-zone/shared/environments';
import { AppNames, APP_NAME } from '@imt-web-zone/shared/core';
import { ImtExceptionCodesEnum } from '@integromat/exceptions';
import { UiToastMessageService } from '@imt-web-zone/make-design-system/ui-toast-message';
import { ApiConfigFacade, ApiConfigProvider, ModeEnum, SsoTypes } from '@imt-web-zone/zone/state-api-config';
import { SessionChecks, SessionChecksService } from '@imt-web-zone/zone/data-access-session-checks';
import { AuthService } from './auth.service';
import { AUTH_STATE_TOKEN } from './auth.symbols';
import { AuthUserModel } from './auth-user.model';
import {
	ChangeAuthUserLanguageRequest,
	changeAuthUserLanguageRequest,
	confirmForcedPasswordChange,
	DecrementUserUnreadNotification,
	decrementUserUnreadNotification,
	Disable2faSuccess,
	disable2faSuccess,
	Enable2faSuccess,
	enable2faSuccess,
	Get2faDataRequest,
	get2faDataRequest,
	LoadAuthUser,
	loadAuthUser,
	Login,
	login,
	Logout,
	logout,
	PatchAuthState,
	patchAuthState,
	registerPartner,
	RegisterPartner,
	SetAuthorizationType,
	TfaLogin,
	tfaLogin,
	UpdateUserFromFormula,
	updateUserFromFormula,
	UpdateUserUnreadNotificationsRequest,
	updateUserUnreadNotificationsRequest,
} from './auth.actions';
import { AuthUserAdapter } from './auth-user.adapter';
import { Get2faResponse, PartialUpdateUserResponse } from './auth.interface';
import { LocaleService } from '@imt-web-zone/shared/data-access';
import { AuthStateModel, getInitialAuthStateModel } from './auth.model';
import { AuthSelectors } from './auth.selectors';
import { ZoneUserCookiesService } from '@imt-web-zone/zone/data-access-storages';
import { GrowthbookService } from '@imt-web-zone/shared/data-access-growthbook';
import { CommonSelectors, changeLogoIndicatorVisibility } from '@imt-web-zone/zone/state-common';
/**
 * ! IMPORTANT NOTE !
 *
 * This file is overridden with auth-inspector.state.ts when referenced from the scope apps/inspector-widget
 *
 */
@State({
	name: AUTH_STATE_TOKEN,
	defaults: getInitialAuthStateModel(),
})
@Injectable()
export class AuthState {
	private service = inject(AuthService);
	private router = inject(Router);
	private ngZone = inject(NgZone);
	private cookie = inject(ZoneUserCookiesService);
	private localeService = inject(LocaleService);
	private transloco = inject(TranslocoService);
	private growthBookService = inject(GrowthbookService);
	private configProvider = inject(ApiConfigProvider);
	private appName = inject<AppNames>(APP_NAME);
	private toast = inject(UiToastMessageService);
	private store = inject(Store);

	private apiConfigFacade = inject(ApiConfigFacade);
	private sessionChecksService = inject(SessionChecksService);
	private tabActive: boolean;
	private sanityCheck: SessionChecks = {
		timer: null,
		callBackFn: () => {
			this.sanityCheck.subscription = this.featureFlagSecureSessionLogout
				? null
				: this.startInterval(environment.api.sanityCheckInterval);
		},
	};

	constructor() {
		this.tabActive = !document.hidden;
		// reset localStorage sessionChecks
		this.sessionChecksService.setLocalStorage({});

		this.growthBookService
			.isOn$('secure_session_logout_web_zone')
			?.pipe(
				take(1),
				switchMap((value) => {
					const sanityCheckTimestamp = this.sessionChecksService.getOrSetLocalStorage()?.sanityCheck;

					if (value && !sanityCheckTimestamp) {
						this.sessionChecksService.getOrSetLocalStorage('sanityCheck');
						return this.service.sanityCheck();
					}
					return of(value);
				}),
			)
			.subscribe((data) => {
				if (typeof data === 'boolean') {
					if (data) {
						if (this.sanityCheck.subscription) {
							this.sanityCheck.subscription.unsubscribe();
						}
						this.sanityCheck.subscription = null;
					} else {
						if (!this.sanityCheck) {
							this.sanityCheck = {
								timer: null,
								callBackFn: () => {
									this.sanityCheck.subscription = this.startInterval(
										environment.api.sanityCheckInterval,
									);
								},
							};
						}
					}
				} else {
					const userSessionExpiresAt = data?.userSessionExpiresAt;
					if (userSessionExpiresAt) {
						this.sessionChecksService.getOrSetLocalStorage('userSessionExpiresAt', userSessionExpiresAt);
					}
				}
			});

		document.addEventListener('visibilitychange', () => {
			this.tabActive = !document.hidden;

			if (this.tabActive && !this.featureFlagSecureSessionLogout) {
				this.getStorageAndStartInterval(true);
			} else {
				this.sanityCheck?.subscription?.unsubscribe();
			}
		});
	}

	private get featureFlagSecureSessionLogout() {
		return this.growthBookService.isOn('secure_session_logout_web_zone');
	}

	public get apiConfig() {
		return this.apiConfigFacade.configSnapshot;
	}

	public get authUser() {
		return this.store.selectSnapshot(AuthSelectors.getAuthUser);
	}
	public get redirectTo() {
		return this.store.selectSnapshot(CommonSelectors.initialUrl);
	}

	public sanityCheckLogout(soft = false, sessionExpired = false, userEmailChanged = false) {
		this.sanityCheck.subscription?.unsubscribe();
		return this.store.dispatch(logout({ payload: { redirect: true, soft, sessionExpired, userEmailChanged } }));
	}

	@Action(tfaLogin)
	public tfaLogin(ctx: StateContext<AuthStateModel>, action: TfaLogin) {
		const { payload } = action.params;
		return effectFactory(ctx, action, this.service.tfaAuth$(payload), 'code', (res) => {
			this.handleLoginSuccess(ctx, this.redirectTo);
			ctx.patchState({
				tfaEnabled: false,
				signedOut: undefined,
			});
		});
	}

	@Action(patchAuthState)
	public patchAuthState(ctx: StateContext<AuthStateModel>, action: PatchAuthState) {
		ctx.setState((state: AuthStateModel) => {
			state = { ...state, ...action.payload };
			return state;
		});
	}

	@Action(SetAuthorizationType)
	@ImmutableContext()
	public setAuthorizationType(ctx: StateContext<AuthStateModel>, action: SetAuthorizationType) {
		ctx.setState((state: AuthStateModel) => {
			state.type = action.payload;
			return state;
		});
	}

	@Action(login)
	public login(ctx: StateContext<AuthStateModel>, action: Login) {
		const { payload } = action.params;
		return effectFactory(ctx, action, this.service.login$(payload), 'userId', (res) => {
			this.handleLoginSuccess(ctx, this.redirectTo, res.tfaEnabled);
			ctx.patchState({
				tfaEnabled: res.tfaEnabled,
				signedOut: undefined,
			});
		});
	}

	/**
	 * Logout with sync true is used when you need just to reset state and redirect
	 */
	@Action(logout)
	public logout(ctx: StateContext<AuthStateModel>, action: Logout) {
		const { payload } = action.params;
		const query = payload?.soft ? { soft: true } : null;
		if (payload?.sync) {
			return this.logoutSuccess(ctx, payload);
		}

		return effectFactory(ctx, action, this.service.logout$(query || undefined), 'ok', (res) => {
			this.logoutSuccess(ctx, payload, res.redirect);
		});
	}

	/**
	 * clearing the state is provided by auth.meta-reducer.ts
	 */
	public logoutSuccess(
		ctx: StateContext<AuthStateModel>,
		payload: Logout['params']['payload'],
		redirectUrl?: string,
	) {
		if (payload?.redirect) {
			if (redirectUrl) {
				window.location.href = redirectUrl;
				return;
			}
			if (this.apiConfig.mode === ModeEnum.SLAVE) {
				let redirectUrl = `https://${this.apiConfig.slaveDomains?.hq}/${this.apiConfig.generalSettings.defaultLanguage}/login`;

				if (payload?.sessionExpired) {
					redirectUrl += '?userSessionExpired=true';
				}

				if (payload?.userEmailChanged) {
					redirectUrl += '?userEmailChanged=true';
				}

				window.location.href = redirectUrl;
				return;
			}
			ctx.patchState({ signedOut: true });
			this.navigate(
				this.apiConfig.ssoType === SsoTypes.OAUTH2 || this.apiConfig.ssoType === SsoTypes.SAML2
					? 'sso/logged-out'
					: '/login',
			);
		}
	}

	@Action(loadAuthUser)
	public loadMe(ctx: StateContext<AuthStateModel>, action: LoadAuthUser) {
		const { query } = action.params;
		return effectFactory(ctx, action, this.service.getMe$(query), 'authUser', async (res) => {
			this.cookie.setVisitedCookie(true);
			await this.setUser(ctx, AuthUserAdapter.toStore(res.authUser));
			this.getStorageAndStartInterval(true);

			if (action.metadata.fromGuard) {
				this.configProvider.loadAppConfig();
			}
		});
	}

	@Action(updateUserFromFormula)
	public async updateUserByInspector(ctx: StateContext<AuthStateModel>, action: UpdateUserFromFormula) {
		const updatedUser = AuthUserAdapter.toStore(action.payload);
		await this.setUser(ctx, updatedUser);
	}

	@Action(updateUserUnreadNotificationsRequest)
	@ImmutableContext()
	public updateUserUnreadNotificationsRequest(
		ctx: StateContext<AuthStateModel>,
		action: UpdateUserUnreadNotificationsRequest,
	) {
		return this.service.usersUnreadNotifications$().pipe(
			tap((res) => {
				ctx.setState((state: AuthStateModel) => {
					state.userUnreadNotifications = res.userUnreadNotifications;
					return state;
				});
			}),
		);
	}

	@Action(decrementUserUnreadNotification)
	@ImmutableContext()
	public readNotifications(ctx: StateContext<AuthStateModel>, action: DecrementUserUnreadNotification) {
		ctx.setState((state: AuthStateModel) => {
			const decreaseAmount = action.count ? action.count : 1;
			if (state.userUnreadNotifications) {
				state.userUnreadNotifications =
					state.userUnreadNotifications > 0 ? state.userUnreadNotifications - decreaseAmount : 0;
			}
			if (this.apiConfig.mode === ModeEnum.SLAVE && action.imtZoneId) {
				const zoneNotifications = state.userZoneUnreadNotifications?.find(
					({ imtZoneId }) => imtZoneId === action.imtZoneId,
				);
				if (zoneNotifications) {
					zoneNotifications.unreadNotifications =
						zoneNotifications.unreadNotifications > 0
							? zoneNotifications.unreadNotifications - decreaseAmount
							: 0;
				}
			}
			return state;
		});
	}

	@Action(confirmForcedPasswordChange)
	@ImmutableContext()
	public confirmForcedPAsswordChange(ctx: StateContext<AuthStateModel>) {
		ctx.setState((state: AuthStateModel) => {
			if (state.user) {
				state.user.forceSetPassword = false;
			}
			return state;
		});
		this.navigate('/');
	}

	@Action(changeAuthUserLanguageRequest)
	public changeAuthUserLanguageRequest(ctx: StateContext<AuthStateModel>, action: ChangeAuthUserLanguageRequest) {
		const { params, payload } = action.params;
		const request = this.service.patchUserLanguage$(params.userId, payload);
		return effectFactory(ctx, action, request, 'user', (res) => {
			this.changeAuthUserLanguageRequestSuccess(ctx, res);
		});
	}

	@ImmutableContext()
	public async changeAuthUserLanguageRequestSuccess(
		ctx: StateContext<AuthStateModel>,
		res: PartialUpdateUserResponse,
	) {
		ctx.setState((state: AuthStateModel) => {
			if (state.user) {
				state.user.language = res.user.language;
			}

			return state;
		});

		if (this.transloco.getActiveLang() !== res.user.language) {
			this.transloco.setActiveLang(res.user.language);
			await firstValueFrom(this.transloco.load(res.user.language));
		}
	}

	@Action(get2faDataRequest)
	public get2faEnableRequest(ctx: StateContext<AuthStateModel>, action: Get2faDataRequest) {
		return effectFactory(ctx, action, this.service.getTfaData$(), 'oneTimePasswords', (res) => {
			this.get2faEnableSuccess(ctx, res);
		});
	}

	@ImmutableContext()
	public get2faEnableSuccess(ctx: StateContext<AuthStateModel>, res: Get2faResponse) {
		ctx.setState((state: AuthStateModel) => {
			if (state.user) {
				state.user.tfaCodes = res.oneTimePasswords;
			}
			return state;
		});
	}

	@Action(enable2faSuccess)
	@ImmutableContext()
	public enable2faSuccess(ctx: StateContext<AuthStateModel>, action: Enable2faSuccess) {
		ctx.setState((state: AuthStateModel) => {
			if (state.user) {
				state.user.tfaCodes = action.payload.oneTimePasswords;
				state.user.tfaEnabled = action.payload.tfaEnabled;
			}
			return state;
		});
	}

	@Action(disable2faSuccess)
	@ImmutableContext()
	public disable2faSuccess(ctx: StateContext<AuthStateModel>, { payload }: Disable2faSuccess) {
		ctx.setState((state: AuthStateModel) => {
			if (state.user) {
				state.user.tfaEnabled = payload.tfaEnabled;
			}
			return state;
		});
	}

	@Action(registerPartner)
	@ImmutableContext()
	public async registerPartner(ctx: StateContext<AuthStateModel>, action: RegisterPartner) {
		try {
			await this.service
				.registerPartner(action.params.payload, ImtExceptionCodesEnum.SC_400_BAD_REQUEST)
				.toPromise();
			ctx.setState((state) => {
				if (state.user) {
					state.user.isAffiliatePartner = true;
				}
				return state;
			});
			this.toast.show({ text: this.transloco.translate('affiliate.registered') });
		} catch (error: any) {
			if (error?.error?.code === ImtExceptionCodesEnum.SC_400_BAD_REQUEST) {
				this.toast.showDanger({
					title: this.transloco.translate('affiliate.registrationFailed'),
					text: this.transloco.translate('affiliate.registrationFailedDesc'),
				});
			}

			// retrhow the error so that ngxs dispatch subscription fails
			throw error;
		}
	}

	private navigate(url: string): void {
		this.ngZone.run(() => this.router.navigateByUrl(url));
	}

	@ImmutableContext()
	private async setUser(ctx: StateContext<AuthStateModel>, newUser: AuthUserModel) {
		// when locale changes, load new locale files and then update user
		const user = ctx.getState().user;
		if (!user || (newUser && user.localeId !== newUser.localeId)) {
			await this.localeService.loadLocale(newUser.localeId);
		}
		ctx.setState((state: AuthStateModel) => this.setUserState(state, newUser));

		if (typeof newUser.language === 'string' && this.transloco.getActiveLang() !== newUser.language) {
			this.transloco.setActiveLang(newUser.language);
			await firstValueFrom(this.transloco.load(newUser.language));
		}
	}

	private setUserState(state: AuthStateModel, newUser: AuthUserModel) {
		state.user = { ...state.user, ...newUser };

		const userCookie = this.cookie.readUserCookie();
		if (userCookie) {
			try {
				const obj = JSON.parse(userCookie);
				state.user.loginAsUser = obj.loginAsUser;
			} catch (e) {
				console.warn('Unexpected user cookie value');
			}
		} else {
			console.warn('User cookie is not defined');
		}
		this.cookie.setZoneCookie(+state.user.id, this.apiConfig?.zoneId);

		return state;
	}

	private handleLoginSuccess(ctx: StateContext<AuthStateModel>, redirectTo?: string, tfaEnabled?: boolean) {
		if (tfaEnabled) {
			return;
		}

		if (this.appName === AppNames.ZONE_HQ_ADMIN) {
			window.location.replace(`/api/post-login`);
		}

		if (redirectTo && redirectTo !== window.location.pathname) {
			this.navigate(redirectTo);
		} else if (window.location.pathname.startsWith('/admin')) {
			this.navigate('/admin');
		} else {
			this.navigate('/');
		}
		ctx.dispatch(changeLogoIndicatorVisibility({ visible: true }));
	}

	private getStorageAndStartInterval(checkForLastRequestTime?: boolean) {
		if (this.apiConfig?.mode === ModeEnum.SLAVE) {
			this.sessionChecksService.getStorageAndStartInterval(
				this.sanityCheck,
				'sanityCheck',
				checkForLastRequestTime,
				environment.api.sanityCheckInterval,
			);
		}
	}

	private startInterval(sessionCheckInterval: number): Subscription {
		const interval$ = interval(sessionCheckInterval).pipe(
			startWith(0),
			takeWhile(() => this.tabActive && !!this.authUser),
			tap(() => {
				this.sessionChecksService.getOrSetLocalStorage('sanityCheck');
			}),
			mergeMap(() => {
				return this.service.sanityCheck().pipe(
					catchError((err) => {
						if (err.error?.status === 401) {
							this.sanityCheckLogout(true, true);
						}
						return of(null);
					}),
				);
			}),
			tap((res) => {
				const userSessionExpiresAt = res?.userSessionExpiresAt;
				if (userSessionExpiresAt) {
					this.sessionChecksService.getOrSetLocalStorage('userSessionExpiresAt', userSessionExpiresAt);
				}
				this.sessionChecksService.getOrSetLocalStorage('sanityCheck');

				if (res && res?.authUser?.email !== this.authUser?.email) {
					this.sanityCheckLogout(true, false, true);
				}
			}),
		);
		return interval$.subscribe();
	}
}
