import { Injectable, inject } from '@angular/core';
import { WebStreamerLib } from './web-streamer.interface';
import { TranslocoService } from '@jsverse/transloco';
import { UiToastMessageService } from '@imt-web-zone/make-design-system/ui-toast-message';

@Injectable({
	providedIn: 'root',
})
export class WebStreamerService {
	private toastService = inject(UiToastMessageService);
	private transloco = inject(TranslocoService);

	private instance: WebStreamerLib = null;

	public initialize() {
		if (this.instance) {
			return;
		}

		if (!(window as any).WebStreamerLib) {
			this.toastService.showDanger({
				text: this.transloco.translate('streamer.streaming_not_initialized'),
			});
			return;
		}

		this.instance = new (window as any).WebStreamerLib();
		this.instance.on('connected', () => {
			// eslint-disable-next-line no-restricted-syntax
			console.info('[imt] web-streamer: connected');
		});

		this.instance.on('disconnected', () => {
			// eslint-disable-next-line no-restricted-syntax
			console.info('[imt] web-streamer: disconnected');
		});

		this.instance.on('info', (msg) => {
			(window as any).imt.debug('info', msg);
		});
		(window as any).webStreamerInstance = this.instance;
	}
}
