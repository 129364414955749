import { Injectable, Renderer2, RendererFactory2, inject as inject_1 } from '@angular/core';
import { WINDOW } from '@imt-web-zone/shared/data-access';
import { ImtUiAssetsInjector } from '@imt-web-zone/shared/util-assets-injector';
import { UtilURL } from '@imt-web-zone/shared/util';

/**
Env Variables Map

NOTE:
// `{{version}}` is replaced on the FE level (via `AssetsService`/`ZoneAssetsService`)

Local Environment
--------------------------------
- Master
FORMAN_CDN_URL=/zone-assets-server/forman/{{version}}

- Slave
FORMAN_CDN_URL=/zone-assets-server/forman/{{version}}

Live environments (infra team)
--------------------------------------------
- Master
FORMAN_CDN_URL=/cdn-proxy/files/forman/branch/master/ (development)
FORMAN_CDN_URL=/zone-assets-server/forman/{{version}} (staging)
FORMAN_CDN_URL=/zone-assets-server/forman/{{version}} (release)
FORMAN_CDN_URL=/zone-assets-server/forman/{{version}} (production)

- Slave
FORMAN_CDN_URL=https://cdn.integromat.dev/files/forman/branch/master (development)
FORMAN_CDN_URL= (staging)
FORMAN_CDN_URL= (release)
FORMAN_CDN_URL= (production)
*/

@Injectable({ providedIn: 'root' })
export class FormanLoaderService {
	private window = inject_1<Window>(WINDOW);
	private rendererFactory = inject_1(RendererFactory2);
	private assetsInjector = inject_1(ImtUiAssetsInjector);

	private renderer: Renderer2;
	private cacheBustingParam = `?_=${process.env['BUILD_TIMESTAMP']}`;

	constructor() {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	public async loadForman({
		staticUrl,
		remoteFormanAdapterUrl,
		remoteFormanUrl,
		cdnFormanUrl,
	}: {
		staticUrl: string;
		remoteFormanAdapterUrl?: string;
		remoteFormanUrl?: string;
		cdnFormanUrl?: string;
	}) {
		// Note that following Forman/Inspector dependencies (`iml`, `jquery`, `bootstrap`) must be loaded
		// via `staticUrl` and they currently exist only there and loading from other URL would fail.

		// forman depends on IML
		await this.assetsInjector.inject(this.renderer, {
			tag: 'script',
			src: UtilURL.urlJoin(staticUrl, '/vendor/iml/iml.min.js', this.cacheBustingParam),
		});

		// jquery is required for ImtUiFormulaTranslateService to be properly initialized
		await this.assetsInjector.inject(this.renderer, {
			tag: 'script',
			src: UtilURL.urlJoin(staticUrl, '/vendor/jquery/jquery.min.js', this.cacheBustingParam),
		});

		await this.assetsInjector.inject(this.renderer, {
			tag: 'script',
			src: UtilURL.urlJoin(staticUrl, '/vendor/bootstrap/bootstrap.bundle.min.js', this.cacheBustingParam),
		});

		this.window['Formula_Old'] = this.window['Formula'];
		if (remoteFormanUrl && remoteFormanAdapterUrl) {
			const res = await this.assetsInjector.inject(this.renderer, [
				{
					tag: 'script',
					position: 'head',
					src: UtilURL.urlJoin(remoteFormanAdapterUrl, this.cacheBustingParam),
				},
				{
					tag: 'script',
					position: 'head',
					src: UtilURL.urlJoin(remoteFormanUrl, this.cacheBustingParam),
				},
			]);

			if (!res.find((sc) => sc.result === 'error')) {
				/* eslint-disable no-restricted-syntax */
				console.info('==============================');
				console.info(`Remote Forman ${window['IMT_FORMAN_VERSION']} loaded!`);
				console.info('==============================');

				/* eslint-enable no-restricted-syntax */
			} else {
				/* eslint-disable no-restricted-syntax */
				console.info('==============================');
				console.info(`Remote Forman could not be loaded! Fallback to local Forman version!`);
				console.info('==============================');

				/* eslint-enable no-restricted-syntax */
				await this.injectForman(staticUrl);
			}
		} else {
			await this.injectForman(staticUrl, cdnFormanUrl);
		}
	}

	private async injectForman(staticUrl: string, cdnUrl?: string) {
		// Try to load Forman from provided CDN URL first (if available and differs from static URL).
		if (cdnUrl && cdnUrl !== staticUrl) {
			const [{ result }] = await this.assetsInjector.inject(this.renderer, [
				{
					tag: 'script',
					position: 'head',
					src: UtilURL.urlJoin(cdnUrl, '/formula-adapter.umd.js', this.cacheBustingParam),
				},
				{
					tag: 'script',
					position: 'head',
					src: UtilURL.urlJoin(cdnUrl, '/forman.umd.js', this.cacheBustingParam),
				},
			]);

			if (result === 'success') {
				return;
			}
		}

		// Fallback to node_modules if loading from CDN fails or if CDN URL is not provided.
		await this.assetsInjector.inject(this.renderer, [
			{
				tag: 'script',
				position: 'head',
				src: UtilURL.urlJoin(staticUrl, '/forman/formula-adapter.umd.js', this.cacheBustingParam),
			},
			{
				tag: 'script',
				position: 'head',
				src: UtilURL.urlJoin(staticUrl, '/forman/forman.umd.js', this.cacheBustingParam),
			},
		]);
	}
}
