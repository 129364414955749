import { Injectable, inject } from '@angular/core';
import { Experiment, GrowthBook, Result } from '@growthbook/growthbook';
import { environment } from '@imt-web-zone/shared/environments';
import { GET, HttpBuilder, QueryData } from '@imt-web-zone/shared/util';
import {
	interval,
	mergeMap,
	Subscription,
	Observable,
	distinctUntilChanged,
	map,
	Subject,
	firstValueFrom,
	filter,
} from 'rxjs';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';
import { AutoExperiment, WidenPrimitives } from '@growthbook/growthbook/dist/types/growthbook';
import { NavigationEnd, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { debugLogger } from '@imt-web-zone/core/util-core';
import { FeatureFlag } from './feature-flag.type';
import { ServiceInit } from '@imt-web-zone/core/util-core';
import { CookiesService } from '@imt-web-zone/shared/data-access-cookies';

const debug = debugLogger.create('growthbook');

export interface FeaturesResponse {
	features: Record<string, unknown>;
	experiments: AutoExperiment[];
	date_updated: string;
}

type DeviceType = 'desktop' | 'mobile';

export interface Context {
	email: string;
	userId: string;
	countryId: string;
	localeId: string;
	timezoneId: string;
	createdAt: number;
	zone: string;
	firstTimeUser: boolean;
}

export interface EventTrack {
	<T>(event: string, payload: T, includeCommonData?: boolean): Observable<unknown>;
}

@Injectable({
	providedIn: 'root',
})
export class GrowthbookService extends HttpBuilder implements ServiceInit {
	private cookieService = inject(CookiesService);
	private router = inject(Router);

	private growthBook = new GrowthBook({
		enableDevMode: !environment.production,
		trackingCallback: this.trackExperiment.bind(this),
	});
	private subscription: Subscription;
	private tabActive: boolean;
	private anonymousId: string;
	private deviceType: DeviceType;
	private initialized = false;
	private wasContextMissing = false;
	private featuresUpdated$ = new Subject<void>();
	private contextChangedSrc = new Subject<void>();
	private _projectName: string;

	private eventTrack: EventTrack;
	private refreshInterval: number;
	private crossDomain = '.make.com';
	private gbContextChanged$: Observable<Context>;
	public readonly warningMessages = {
		multipleInit: 'initialize() method is called more than once!\nCheck your implementation.',
		featureBeforeInit:
			'Feature flag cannot be use until growthbook is initialized.\nGrowthbook initialize() method must be called first.',
		featureValueBeforeInit:
			'Feature value cannot be use until growthbook is initialized.\nGrowthbook initialize() method must be called first.',
	};

	public get gbContext(): object {
		return this.growthBook.getAttributes();
	}

	public get projectName(): string {
		return this._projectName;
	}

	public readonly contextChanged$ = this.contextChangedSrc.asObservable();

	constructor() {
		const http = inject(HttpApiClient);

		super(http);
		this.tabActive = !document.hidden;

		document.addEventListener('visibilitychange', () => {
			this.tabActive = !document.hidden;
			this.startFeatureMonitoring();
		});
	}

	public async initialize(config: {
		eventTrack: EventTrack;
		onContextChange$: Observable<Context>;
		projectName: string;
		refreshInterval: number;
		crossDomain?: string;
		initialContext?: Partial<Context>;
		mobileMaxWidth?: number;
	}): Promise<void> {
		const { eventTrack, onContextChange$, projectName, refreshInterval, crossDomain } = config;
		if (this.initialized) {
			debug(this.warningMessages.multipleInit);
			return;
		}
		this._projectName = projectName;
		this.refreshInterval = refreshInterval;
		crossDomain && (this.crossDomain = crossDomain);

		try {
			this.growthBook.log = (msg: string, ctx: Record<string, unknown>) => {
				this.countMissingContext(msg, ctx);
			};
			const { features, experiments } = await firstValueFrom(
				this.loadFeatures$({ projectName: this.projectName }),
			);
			this.anonymousId = this.getAnonymousId();
			this.deviceType = this.getDeviceType(config.mobileMaxWidth);
			this.eventTrack = eventTrack;
			this.gbContextChanged$ = onContextChange$;
			this.growthBook.setAttributes({
				anonId: this.anonymousId,
				device: this.deviceType,
				...config.initialContext,
			});
			this.growthBook.setFeatures(features);
			this.growthBook.setExperiments(experiments);
			this.growthBook.setURL(window.location.href);

			this.initialized = true;
			this.startFeatureMonitoring();
			this.setGrowthbookAttributes();
			debug('initialized');
		} catch (e) {
			console.error('[GB] Init error: ', e);
		}

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.growthBook.setURL(window.location.href);
		});
	}

	private trackExperiment(experiment: Experiment<string>, variation: Result<unknown>) {
		const attr = this.growthBook.getAttributes();
		const trackingExperimentObject = {
			anon_id: this.anonymousId,
			device: this.deviceType,
			user_id: attr.userId || null,
			project_name: this.projectName,
			feature_id: variation.featureId,
			value: variation.value,
			experiment_id: experiment.key,
			variation_id: variation.variationId,
			in_experiment: variation.inExperiment,
			hash_used: variation.hashUsed,
		};

		if (typeof trackingExperimentObject.value !== 'string') {
			trackingExperimentObject.value = JSON.stringify(trackingExperimentObject.value);
		}

		debug(`in experiment:`, trackingExperimentObject);

		this.eventTrack('experiment_tracking', trackingExperimentObject).subscribe();
	}

	private countMissingContext(msg: string, ctx: Record<string, any>) {
		if (msg === 'Use default value' && this.wasContextMissing) {
			debug(`missing context: `, msg, ctx);
			this.eventTrack(
				'overseer_stats',
				{
					catalog: 'features.no-context',
					value: 1,
					tags: [`project:${this.projectName}`, `name:${ctx?.id}`, `value:${ctx?.value}`],
					type: 'gauge',
				},
				false,
			).subscribe();
		}

		this.wasContextMissing = msg === 'Skip because missing hashAttribute';
	}

	private setGrowthbookAttributes() {
		this.gbContextChanged$.subscribe((context) => {
			this.growthBook.setAttributes({
				...this.growthBook.getAttributes(),
				...context,
			});
			this.contextChangedSrc.next(void 0);
			this.featuresUpdated$.next(void 0);
		});
	}

	private startFeatureMonitoring() {
		if (this.tabActive && this.initialized) {
			this.subscription = this.periodicallyFetchFeatures$().subscribe((gbResponse) => {
				this.growthBook.setFeatures(gbResponse.features);
				this.growthBook.setExperiments(gbResponse.experiments);
				this.featuresUpdated$.next(void 0);
			});
		} else {
			this.subscription?.unsubscribe();
		}
	}

	private periodicallyFetchFeatures$(): Observable<FeaturesResponse> {
		return interval(this.refreshInterval).pipe(
			mergeMap(() => this.loadFeatures$({ projectName: this.projectName })),
			distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
		);
	}

	@GET('features')
	private loadFeatures$(@QueryData query: { projectName: string }): Observable<FeaturesResponse> {
		return undefined;
	}

	private getAnonymousId(): string {
		let id = this.cookieService.readCookie('gb_anonymous_id');
		const uuidv4Pattern = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

		if (!id || !uuidv4Pattern.test(id)) {
			id = uuidv4();
			this.cookieService.setCookie('gb_anonymous_id', id, this.crossDomain, 365); // the cookie expires in 1 year
		}

		return id;
	}

	private getDeviceType(maxMobileWidth = 575): DeviceType {
		if ('userAgentData' in navigator) {
			if ((navigator.userAgentData as any).mobile === true) {
				return 'mobile';
			} else if ((navigator.userAgentData as any).mobile.mobile === false) {
				return 'desktop';
			}
		}

		if (matchMedia(`(max-width: ${maxMobileWidth}px)`).matches) {
			return 'mobile';
		}

		return 'desktop';
	}

	public isOn(feature: FeatureFlag): boolean {
		if (!this.initialized) {
			debug(this.warningMessages.featureBeforeInit);
			return false;
		}
		return this.growthBook.isOn(feature) || !!localStorage.getItem(feature);
	}

	public isOff(feature: FeatureFlag): boolean {
		if (!this.initialized) {
			debug(this.warningMessages.featureBeforeInit);
			return false;
		}
		return this.growthBook.isOff(feature) && !localStorage.getItem(feature);
	}

	public getFeatureValue<T>(feature: FeatureFlag, fallback: T): WidenPrimitives<T> | T {
		if (!this.initialized) {
			debug(this.warningMessages.featureValueBeforeInit);
			return fallback;
		}
		return this.growthBook.getFeatureValue<T, string>(feature, fallback);
	}

	public isOn$(feature: FeatureFlag): Observable<boolean> {
		return this.featuresUpdated$.pipe(
			map(() => this.growthBook.isOn(feature) || !!localStorage.getItem(feature)),
			distinctUntilChanged(),
		);
	}

	public isOff$(feature: FeatureFlag): Observable<boolean> {
		return this.featuresUpdated$.pipe(
			map(() => this.growthBook.isOff(feature) && !localStorage.getItem(feature)),
			distinctUntilChanged(),
		);
	}

	public getFeatureValue$<T>(feature: FeatureFlag, fallback: T): Observable<WidenPrimitives<T>> {
		return this.featuresUpdated$.pipe(
			map(() => this.growthBook.getFeatureValue<T, string>(feature, fallback)),
			distinctUntilChanged(),
		);
	}
}
